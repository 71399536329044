import React from 'react';
import { Header } from 'components/theme';
import { Layout, SEO } from 'components/common';
import { Intro, Laptop, Steps, CustomerView, Press } from 'components/menus';
import { SocialProof } from 'components/menus/SocialProof';

export default () => (
  <Layout>
    <SEO type="menus" />
    <Header product="menus" />
    <Intro />
    <Press />
    <Steps />
    <Laptop />
    <CustomerView />
    <SocialProof />
  </Layout>
);
