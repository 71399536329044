import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Wrapper, Content, StyledImage } from './styles';

export const CustomerView: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/iphone-menu.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      <StyledImage fluid={data.file.childImageSharp.fluid} />
      <Content>
        <h2>A Simple Touchless Experience For Your Customers</h2>
        <p>
          Simply place our QR Code and URL for customers to scan and browse your menu. 
          {' '}<a href='https://cards.paneau.io/menu/drapercafe'>Try it for yourself!</a>
        </p>
      </Content>
    </Wrapper>
  );
};
