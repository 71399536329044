import React, { MouseEvent } from 'react';
import { Wrapper, Logos, EnlargedLogo } from './styles';

export const SocialProof: React.FC = () => (
  <Wrapper>
    <h2>Pricing?</h2>
    <h3>It's free! We just hope it helps :)</h3>
  </Wrapper>
);

export default SocialProof;
