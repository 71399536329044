import React from 'react';
import { Wrapper, Paper, StepsContainer, IconWrapper } from './styles';
import Account from 'assets/icons/account.inline.svg';
import GiftCard from 'assets/icons/gift-card.inline.svg';
import MenuImage from 'assets/icons/menu.inline.svg';
import Network from 'assets/icons/network.inline.svg';

export const Steps: React.FC = () => {
  return (
    <Wrapper>
      <h2>Creating digitals menus is easy.</h2>
      <p>
        Creating a touchless experience for your guests with digital menus is easy.
        We're here to support you and your business, so signing up and using our
        menu platform is free.
      </p>
      <StepsContainer>
        <Paper>
          <h3>Create an Account</h3>
          <IconWrapper>
            <Account width="50%" />
          </IconWrapper>
          <p>
            Use the sign-up form above and follow our guided account setup flow.
          </p>
        </Paper>
        <Paper>
          <h3>Create a Digital Menu</h3>
          <IconWrapper>
            <MenuImage width="50%" />
          </IconWrapper>
          <p>
            Use our simple menu maker app.
          </p>
        </Paper>
        <Paper>
          <h3>Share Your Digital Menu With Customers</h3>
          <IconWrapper>
            <Network width="50%" />
          </IconWrapper>
          <p>
            We'll help you put a QR Code and link on every table so customers can safely browse your menu from their phone.
          </p>
        </Paper>
      </StepsContainer>
    </Wrapper>
  );
};
